import { createApp } from "vue";
import App from "@/App.vue";
import router from "@/router";
import commonPlugin from "common-plugin";
import "@/assets/sass/main.scss";

const app = createApp(App);

app
  .use(commonPlugin)
  .use(commonPlugin.store)
  .use(router)
  .mount("#app");
