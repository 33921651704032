<template>
  <router-view />
</template>

<script>
import { mapMutations, mapGetters } from "vuex";

export default {
  name: "App",
  data() {
    return {};
  },
  methods: {
    ...mapMutations("common", ["initProcessEnv"]),
    ...mapGetters("common", ["processEnv", "branchDivision"]),
    getProcessEnv() {
      let processEnv = process.env;
      let result = {};
      if (processEnv && Object.keys(processEnv).length > 0) {
        Object.keys(processEnv).forEach((key) => {
          result[key] = processEnv[key];
        });
      }

      return result;
    },
  },
  created() {
    let processEnv = this.getProcessEnv();
    this.initProcessEnv(processEnv);
  },
};
</script>
